import React from 'react';
import PropTypes from 'prop-types';
import './Dropdown.scss';

const Dropdown = ({ options, fieldToDisplay, valueToGet, label, ...rest }) => {
  return (
    <section className="dropdown" {...rest}>
      {label && <p>{label}</p>}
      <select className="dropdown__select">
        {options.map((item, index) => (
          <option
            key={index.toString()}
            className="dropdown__select__option"
            value={item[valueToGet]}
          >
            {item[fieldToDisplay]}
          </option>
        ))}
      </select>
    </section>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array,
  fieldToDisplay: PropTypes.string.isRequired,
  valueToGet: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default Dropdown;
