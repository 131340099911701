export const userNavigation = [
  {
    title: 'Bord',
    icon: 'flaticon-dashboard',
    param: 'dashboard'
  },
  {
    title: 'Profil',
    icon: 'flaticon-user-2',
    param: 'user-profile'
  },
  {
    title: 'Anunturile mele',
    icon: 'flaticon-list',
    param: 'my-listing'
  },
  {
    title: 'Anunturi favorite',
    icon: 'flaticon-heart',
    param: 'favorites'
  },
  {
    title: 'Adauga anunt',
    icon: 'flaticon-plus',
    param: 'add-listing'
  },
  {
    title: 'Mesaje',
    icon: 'flaticon-message',
    param: 'messages'
  },
  {
    title: 'Iesire din cont',
    icon: 'flaticon-logout',
    param: 'logout'
  }
];
