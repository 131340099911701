import React, { useCallback, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';
import logo from '../../assets/logo.svg';
import Sidemenu from './Sidemenu/Sidemenu';
import Button from '../../shared/Button';
import useOutsideClick from '../../utils/hooks/useOutsideClick';
import './Header.scss';

const Header = () => {
  const sidemenuRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);

  const usersState = useSelector((state) => state.users, shallowEqual);
  useOutsideClick(sidemenuRef, () => setToggleMenu(false));

  const handleMyAccount = useCallback(() => {
    if (!usersState.isLoggedIn) {
      navigate('/login');
      return;
    }

    setToggleMenu(true);
  }, [navigate, usersState.isLoggedIn]);

  const navClass = classNames({
    header_dark_text: location.pathname !== '/'
  });

  return (
    <header className="header">
      <div className="header__container">
        <div className="header__container__logo" onClick={() => navigate('/')}>
          <img src={logo} alt="2x4" />
        </div>

        <nav className="header__container__navigation">
          <p className={navClass} onClick={handleMyAccount}>
            Contul tau
            {usersState.isLoggedIn && (
              <span className="material-symbols-outlined">arrow_right</span>
            )}
          </p>
          <div className="header__container__navigation__add-button">
            <Button text="Adauga anunt" color="yellow" width={130} />
          </div>
        </nav>

        {/* <div className='header__container__menu' onClick={() => setToggleMenu(!toggleMenu)}>
          <span />
          <span />
        </div> */}

        {toggleMenu && <Sidemenu onClose={() => setToggleMenu(false)} ref={sidemenuRef} />}
      </div>
    </header>
  );
};

export default Header;
