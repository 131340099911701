import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './LogMessage.scss';

const LogMessage = ({ type, message }) => {
  const classConfig = classNames({
    'log-message': true,
    'log-message-success': type === 'success',
    'log-message-warning': type === 'warning',
    'log-message-error': type === 'error'
  });

  const renderIcon = useMemo(() => {
    if (type === 'success') return 'check';
    if (type === 'warning') return 'warning';

    return 'error';
  }, [type]);

  return (
    <div className={classConfig}>
      <span className="material-symbols-outlined">{renderIcon}</span>
      {message}
    </div>
  );
};

LogMessage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string
};

export default LogMessage;
