/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm';
import ConnectSocial from '../../components/ConnectSocial/ConnectSocial';
import './Login.scss';

const Login = () => {
  const navigate = useNavigate();

  return (
    <article className="login">
      <section className="login__container">
        <section className="login__container__content">
          <h2>Autentifica-te</h2>
          <p>
            Esti nou pe 2x4.ro?
            <a href="#" onClick={() => navigate('/signup')}>
              {' '}
              Inregistreaza-te
            </a>
            .
          </p>
          <LoginForm />
        </section>
        <ConnectSocial />
      </section>
    </article>
  );
};

export default Login;
