/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Input from '../../shared/Input/Input';
import Button from '../../shared/Button/Button';
import LogMessage from '../../shared/LogMessage/LogMessage';
import { getLogin, resetUsers } from '../../actions/users';
import './LoginForm.scss';

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayError, setDisplayError] = useState(null);

  const usersState = useSelector((state) => state.users, shallowEqual);

  useEffect(() => {
    if (usersState.isLoggedIn) {
      navigate('/');
    }
  }, [navigate, usersState.isLoggedIn]);

  useEffect(() => {
    if (usersState.fetchedLogin) {
      setDisplayError(null);

      if (usersState.fetchedLogin && usersState.errorLogin) {
        setDisplayError({
          type: 'error',
          message: usersState.errorLogin.error
        });
      }
    }
  }, [usersState.errorLogin, usersState.fetchedLogin]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const email = e.target[0].value;
      const password = e.target[1].value;

      const credentials = {
        logEmail: email,
        logPassword: password
      };

      dispatch(getLogin(credentials));
    },
    [dispatch]
  );

  const handleForgotPassword = useCallback(
    (e) => {
      e.preventDefault();
      navigate('/forgotPassword');
    },
    [navigate]
  );

  useEffect(() => {
    return () => {
      dispatch(resetUsers());
    };
  }, [dispatch]);

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="login-form__email">
        <p>Adresa de e-mail *</p>
        <Input type="email" placeholder="E-mail" required />
      </div>
      <div className="login-form__password">
        <p>Parola *</p>
        <Input type="password" placeholder="Parola" required />
      </div>
      <div className="login-form__options">
        <Button
          text={'Autentifica'}
          color="yellow"
          width={185}
          fetching={usersState.isFetchingLogin}
        />
        <a href="#" onClick={handleForgotPassword}>
          Ai uitat parola?
        </a>
      </div>
      {displayError && <LogMessage type={displayError.type} message={displayError.message} />}
    </form>
  );
};

export default LoginForm;
