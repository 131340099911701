import React, { useCallback, useMemo, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import closeIcon from '../../../assets/close.png';
import { userNavigation } from '../../../constants/userNavigation';
import { getLogout } from '../../../actions/users';
import './Sidemenu.scss';

const Sidemenu = forwardRef(({ onClose }, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const usersState = useSelector((state) => state.users, shallowEqual);

  const userAbreviation = useMemo(() => {
    return `${usersState.userProfile?.first_name[0] || ''}${usersState.userProfile?.last_name[0] || ''}`;
  }, [usersState.userProfile?.first_name, usersState.userProfile?.last_name]);

  const handleNavigate = useCallback(
    (param) => {
      if (param === 'logout') {
        dispatch(getLogout());
        onClose();
        navigate('/');
        return;
      }

      navigate(`/board/${param}`);
      onClose();
    },
    [dispatch, navigate, onClose]
  );

  return (
    <aside className="sidemenu" ref={ref}>
      <div className="sidemenu__header">
        <div className="sidemenu__header__user-info">
          <div className="sidemenu__header__user-info__img">
            <h3>{userAbreviation}</h3>
          </div>
          <p>
            Salut, {usersState.userProfile?.first_name || ' '}{' '}
            {usersState.userProfile?.last_name || ' '}
          </p>
        </div>

        <button onClick={onClose}>
          <img src={closeIcon} alt="" />
        </button>
      </div>

      <ul className="sidemenu__menu-list">
        {userNavigation.map((item, index) => (
          <li
            key={index.toString()}
            className="sidemenu__menu-list__list"
            onClick={() => handleNavigate(item.param)}
          >
            <i className={item.icon}></i>
            <span>{item.title}</span>
          </li>
        ))}
      </ul>
    </aside>
  );
});

Sidemenu.propTypes = {
  onClose: PropTypes.func
};

export default Sidemenu;
