import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Card from '../../shared/Card';
import './Dashboard.scss';

const Dashboard = () => {
  const usersState = useSelector((state) => state.users, shallowEqual);

  return (
    <div className="dashboard">
      <h2>
        Salut, {usersState.userProfile?.first_name || ''} {usersState.userProfile?.last_name || ''}
      </h2>
      <p>Iata cateva statistici despre activitatea ta.</p>

      <section className="dashboard__infos">
        <Card>
          <div className="dashboard__infos__content">
            <div className="dashboard__infos__content__list-icon">
              <span className="material-symbols-outlined">summarize</span>
            </div>

            <div className="dashboard__infos__content__list-text">
              <h2>1260</h2>
              <p>Anunturile tale</p>
            </div>
          </div>
        </Card>

        <Card>
          <div className="dashboard__infos__content">
            <div className="dashboard__infos__content__messages-icon">
              <span className="material-symbols-outlined">chat</span>
            </div>

            <div className="dashboard__infos__content__messages-text">
              <h2>70</h2>
              <p>Mesaje</p>
            </div>
          </div>
        </Card>

        <Card>
          <div className="dashboard__infos__content">
            <div className="dashboard__infos__content__fav-icon">
              <span className="material-symbols-outlined">favorite</span>
            </div>

            <div className="dashboard__infos__content__fav-text">
              <h2>20</h2>
              <p>Anunturi favorite</p>
            </div>
          </div>
        </Card>
      </section>
    </div>
  );
};

export default Dashboard;
