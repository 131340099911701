import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../shared/Input/Input';
import Button from '../../shared/Button';
import LogMessage from '../../shared/LogMessage/LogMessage';
import { resetPassword } from '../../actions/users';
import './ResetPassword.scss';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const [displayError, setDisplayError] = useState(null);

  const usersState = useSelector((state) => state.users, shallowEqual);

  useEffect(() => {
    if (usersState.fetchedReset) {
      setDisplayError(null);

      if (usersState.fetchedReset && usersState.errorReset) {
        setDisplayError({
          type: 'error',
          message: usersState.errorReset.error || usersState.errorReset.message
        });
      }

      if (usersState.fetchedReset && !usersState.errorReset) {
        setDisplayError({
          type: 'success',
          message: 'Parola a fost resetata cu succes.'
        });
      }
    }
  }, [usersState.errorReset, usersState.fetchedReset]);

  useEffect(() => {
    if (usersState.fetchedReset && !usersState.errorReset) {
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    }
  }, [navigate, usersState.errorReset, usersState.fetchedReset]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const pass = e.target[0].value;
      const confirmPass = e.target[1].value;

      if (pass !== confirmPass) {
        setDisplayError({
          type: 'error',
          message: 'Parola nu corespunde in ambele campuri.'
        });

        return;
      }

      const password = {
        newPassword: pass
      };

      dispatch(resetPassword(password, token));
    },
    [dispatch, token]
  );

  return (
    <article className="reset-password">
      <section className="reset-password__container">
        <h2>Reseteaza-ti acum parola.</h2>
        <form onSubmit={handleSubmit}>
          <Input placeholder="Noua parola" width={500} required />
          <Input placeholder="Confirma parola" width={500} required />
          <Button text="Resetează" color="yellow" width={150} />
          {displayError && <LogMessage type={displayError.type} message={displayError.message} />}
        </form>
      </section>
    </article>
  );
};

export default ResetPassword;
