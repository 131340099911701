export const SEARCH_TABS = [
  {
    id: 1,
    title: 'Item 1',
    param: 'item1'
  },
  {
    id: 2,
    title: 'Item 2',
    param: 'item2'
  },
  {
    id: 3,
    title: 'Item 3',
    param: 'item3'
  }
];
