const httpConfig = () => {
  return {
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

const http = {
  get: () => ({
    method: 'GET',
    credentials: 'include',
    ...httpConfig()
  }),
  post: (payload) => ({
    method: 'POST',
    credentials: 'include',
    ...httpConfig(),
    body: JSON.stringify(payload)
  }),
  put: (payload) => ({
    method: 'PUT',
    credentials: 'include',
    ...httpConfig(),
    body: JSON.stringify(payload)
  }),
  delete: () => ({
    method: 'DELETE',
    credentials: 'include',
    ...httpConfig()
  })
};

export default http;
