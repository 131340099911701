import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Input from '../../shared/Input/Input';
import Button from '../../shared/Button';
import LogMessage from '../../shared/LogMessage/LogMessage';
import { forgotPassword, resetUsers } from '../../actions/users';
import './ForgotPassword.scss';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [displayError, setDisplayError] = useState(null);

  const usersState = useSelector((state) => state.users, shallowEqual);

  useEffect(() => {
    if (usersState.fetchedForgot) {
      setDisplayError(null);

      if (usersState.fetchedForgot && usersState.errorForgot) {
        setDisplayError({
          type: 'error',
          message: usersState.errorForgot.error
        });
      }

      if (usersState.fetchedForgot && !usersState.errorForgot) {
        setDisplayError({
          type: 'success',
          message: 'Un e-mail a fost trimis catre dumneavoastră.'
        });
      }
    }
  }, [usersState.errorForgot, usersState.fetchedForgot]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const mail = e.target[0].value;

      dispatch(forgotPassword({ email: mail }));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(resetUsers());
    };
  }, [dispatch]);

  return (
    <article className="forgot-password">
      <section className="forgot-password__container">
        <h2>Ai uitat parola?</h2>
        <p>Ce adresă de email este asociată contului tău 2x4?</p>
        <form onSubmit={handleSubmit}>
          <Input placeholder="E-mail" width={500} required />
          <Button text="Resetează" color="yellow" width={150} />
          {displayError && <LogMessage type={displayError.type} message={displayError.message} />}
        </form>
      </section>
    </article>
  );
};

export default ForgotPassword;
