import { configureStore } from '@reduxjs/toolkit';
import { createRequestMiddleware } from 'redux-requests';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import { reducers } from '../reducers/reducers';

function createStore(middlewares = [], initialState) {
  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: () => [thunk, createRequestMiddleware(), ...middlewares],
    devTools: window.APP_ENV !== 'prod'
  });

  return {
    store,
    Provider
  };
}

export default createStore;
