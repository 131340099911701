import fetch from './createAction';
import config from './paths';
import http from './http';

const api = {
  fetch,
  config,
  http
};

export default api;
