import {
  GET_USER_PROFILE,
  GET_LOGIN,
  GET_SIGNUP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_USERS,
  GET_LOGOUT
} from '../constants/actionTypes';
import api from '../api';

export const getLogin = (credentials) => (dispatch) => {
  return dispatch(api.fetch(GET_LOGIN, api.config.login(), api.http.post(credentials)));
};

export const getSignup = (payload) => (dispatch) => {
  return dispatch(api.fetch(GET_SIGNUP, api.config.signup(), api.http.post(payload)));
};

export const getUserProfile = (mail) => (dispatch) => {
  return dispatch(api.fetch(GET_USER_PROFILE, api.config.userProfile(), api.http.get()));
};

export const forgotPassword = (mail) => (dispatch) => {
  return dispatch(api.fetch(FORGOT_PASSWORD, api.config.forgotPassword(), api.http.post(mail)));
};

export const resetPassword = (password, token) => (dispatch) => {
  return dispatch(
    api.fetch(RESET_PASSWORD, api.config.resetPassword(token), api.http.post(password))
  );
};

export const getLogout = () => (dispatch) => {
  return dispatch(api.fetch(GET_LOGOUT, api.config.logout(), api.http.post()));
};

export const resetUsers = () => {
  return {
    type: RESET_USERS
  };
};
