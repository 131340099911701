import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { getUserProfile } from './actions/users';
import ProtectedRoutes from './shared/ProtectedRoutes/ProtectedRoutes';
import Header from './components/Header';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import UserBoard from './pages/UserBoard/UserBoard';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import './App.scss';

function App() {
  const dispatch = useDispatch();
  const usersState = useSelector((state) => state.users, shallowEqual);

  useEffect(() => {
    if (!usersState.isLoggedIn || usersState.fetchedLogin) {
      dispatch(getUserProfile());
    }
  }, [dispatch, usersState.fetchedLogin, usersState.isLoggedIn]);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/board"
            element={
              <ProtectedRoutes>
                <UserBoard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/board/:tab"
            element={
              <ProtectedRoutes>
                <UserBoard />
              </ProtectedRoutes>
            }
          />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
