import React, { useCallback, useState } from 'react';
import { SEARCH_TABS } from '../../constants/constants';
import Dropdown from '../../shared/Dropdown/Dropdown';
import Button from '../../shared/Button';
import './HomeSearch.scss';

const HomeSearch = () => {
  const [tab, setTab] = useState(1);

  const handleSelectTab = useCallback((tab) => {
    setTab(tab);
  }, []);

  return (
    <section className="home-search">
      <div className="home-search__tabs">
        {SEARCH_TABS.map((item) => (
          <div
            key={item.id}
            className={`home-search__tabs__tab ${item.id === tab ? 'home-search-selected-tab' : ''}`}
            onClick={() => handleSelectTab(item.id)}
          >
            {item.title}
          </div>
        ))}
      </div>

      <div className="home-search__container">
        <div className="home-search__container__drops">
          <Dropdown options={SEARCH_TABS} fieldToDisplay="title" valueToGet="param" label="Brand" />
          <Dropdown options={SEARCH_TABS} fieldToDisplay="title" valueToGet="param" label="Model" />
          <Dropdown options={SEARCH_TABS} fieldToDisplay="title" valueToGet="param" label="Pret" />
        </div>
        <Button text="Cauta" color="yellow" width={120} />
      </div>
    </section>
  );
};

export default HomeSearch;
