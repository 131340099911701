import logger from './logger';

const getMiddlewares = () => {
  const middlewares = [];
  if (window.APP_ENV !== 'prod') {
    middlewares.push(logger);
  }
  return middlewares;
};

export default getMiddlewares;
