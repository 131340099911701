import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getLogout } from '../../../actions/users';
import { userNavigation } from '../../../constants/userNavigation';
import useOutsideClick from '../../../utils/hooks/useOutsideClick';
import './UserNavigation.scss';

const UserNavigation = ({ selected }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listRef = useRef();
  const [displayList, setDisplayList] = useState(false);

  useOutsideClick(listRef, () => setDisplayList(false));

  const toggleDisplay = useCallback(() => {
    setDisplayList(!displayList);
  }, [displayList]);

  const handleNavigation = useCallback(
    (param) => {
      if (param === 'logout') {
        dispatch(getLogout());
        setDisplayList(false);
        navigate('/');
        return;
      }

      navigate(`/board/${param}`);
      setDisplayList(false);
    },
    [dispatch, navigate]
  );

  return (
    <>
      <div className="user-navigation__mobile" ref={listRef}>
        <button className="user-navigation__mobile__dropdown" onClick={toggleDisplay}>
          <span className="material-symbols-outlined">menu</span>
          User Navigation
        </button>

        {displayList && (
          <section className="user-navigation__mobile__list">
            {userNavigation.map((item, index) => (
              <button
                key={index}
                onClick={() => handleNavigation(item.param)}
                className={selected === item.param ? 'selected-item' : null}
              >
                <i className={item.icon}></i>
                <span>{item.title}</span>
              </button>
            ))}
          </section>
        )}
      </div>

      <div className="user-navigation__desktop">
        {userNavigation.map((item, index) => (
          <button
            key={index}
            onClick={() => handleNavigation(item.param)}
            className={selected === item.param ? 'selected-item' : ''}
          >
            <i className={item.icon}></i>
            <span>{item.title}</span>
          </button>
        ))}
      </div>
    </>
  );
};

UserNavigation.propTypes = {
  selected: PropTypes.string
};

export default UserNavigation;
