import React from 'react';
import HomeSearch from '../../components/HomeSearch/HomeSearch';
import './Home.scss';

const Home = () => {
  return (
    <div className="home">
      <section className="home__search">
        <h1>Gaseste-ti urmatorul model.</h1>
        <HomeSearch />
      </section>
    </div>
  );
};

export default Home;
