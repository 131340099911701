// this is an example of a reducer
import {
  GET_USER_PROFILE,
  GET_LOGIN,
  GET_SIGNUP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_USERS,
  GET_LOGOUT
} from '../constants/actionTypes';
import { PENDING, SUCCESS, ERROR } from '../constants/status';

export const initialState = {
  userProfile: null,
  isLoggedIn: false,
  isFetching: false,
  isFetchingLogin: false,
  isFetchingSignup: false,
  isFetchingForgot: false,
  isFetchingReset: false,
  isFetchingLogout: false,
  fetched: false,
  fetchedLogin: false,
  fetchedSignup: false,
  fetchedForgot: false,
  fetchedReset: false,
  fetchedLogout: false,
  error: null,
  errorLogin: null,
  errorSignup: null,
  errorForgot: null,
  errorReset: null,
  errorLogout: null
};

const users = (state = initialState, action = {}) => {
  const { type, response, error } = action;

  switch (type) {
    case `${GET_USER_PROFILE}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        users: null,
        error: null
      };
    case `${GET_USER_PROFILE}_${SUCCESS}`:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        isLoggedIn: true,
        userProfile: response
      };
    case `${GET_USER_PROFILE}_${ERROR}`:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        error
      };

    // Login
    case `${GET_LOGIN}_${PENDING}`:
      return {
        ...state,
        isFetchingLogin: true,
        users: null,
        errorLogin: null
      };
    case `${GET_LOGIN}_${SUCCESS}`:
      return {
        ...state,
        isFetchingLogin: false,
        fetchedLogin: true,
        isLoggedIn: true
      };
    case `${GET_LOGIN}_${ERROR}`:
      return {
        ...state,
        isFetchingLogin: false,
        fetchedLogin: true,
        errorLogin: error
      };

    // Signup
    case `${GET_SIGNUP}_${PENDING}`:
      return {
        ...state,
        isFetchingSignup: true,
        users: null,
        errorSignup: null
      };
    case `${GET_SIGNUP}_${SUCCESS}`:
      return {
        ...state,
        isFetchingSignup: false,
        fetchedSignup: true
      };
    case `${GET_SIGNUP}_${ERROR}`:
      return {
        ...state,
        isFetchingSignup: false,
        fetchedSignup: true,
        errorSignup: error
      };

    // Forgot password
    case `${FORGOT_PASSWORD}_${PENDING}`:
      return {
        ...state,
        isFetchingForgot: true,
        errorForgot: null
      };
    case `${FORGOT_PASSWORD}_${SUCCESS}`:
      return {
        ...state,
        isFetchingForgot: false,
        fetchedForgot: true
      };
    case `${FORGOT_PASSWORD}_${ERROR}`:
      return {
        ...state,
        isFetchingForgot: false,
        fetchedForgot: true,
        errorForgot: error
      };

    // Reset password
    case `${RESET_PASSWORD}_${PENDING}`:
      return {
        ...state,
        isFetchingReset: true,
        errorReset: null
      };
    case `${RESET_PASSWORD}_${SUCCESS}`:
      return {
        ...state,
        isFetchingReset: false,
        fetchedReset: true
      };
    case `${RESET_PASSWORD}_${ERROR}`:
      return {
        ...state,
        isFetchingReset: false,
        fetchedReset: true,
        errorReset: error
      };

    // Logout
    case `${GET_LOGOUT}_${PENDING}`:
      return {
        ...state,
        isFetchingLogout: true,
        errorLogout: null
      };
    case `${GET_LOGOUT}_${SUCCESS}`:
      return {
        ...state,
        isFetchingLogout: false,
        isLoggedIn: false,
        userProfile: null,
        fetchedLogout: true
      };
    case `${GET_LOGOUT}_${ERROR}`:
      return {
        ...state,
        isFetchingLogout: false,
        fetchedLogout: true,
        errorLogout: error
      };

    // Reset users
    case RESET_USERS:
      return {
        ...state,
        isFetching: false,
        isFetchingLogin: false,
        isFetchingSignup: false,
        isFetchingForgot: false,
        isFetchingReset: false,
        isFetchingLogout: false,
        fetched: false,
        fetchedLogin: false,
        fetchedSignup: false,
        fetchedForgot: false,
        fetchedReset: false,
        fetchedLogout: false,
        error: null,
        errorLogin: null,
        errorSignup: null,
        errorForgot: null,
        errorReset: null,
        errorLogout: null
      };

    default: {
      return state;
    }
  }
};

export default users;
