/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import SignupForm from '../../components/SignupForm/SignupForm';
import ConnectSocial from '../../components/ConnectSocial/ConnectSocial';
import './Signup.scss';

const Signup = () => {
  const navigate = useNavigate();

  const usersState = useSelector((state) => state.users, shallowEqual);

  useEffect(() => {
    if (usersState.fetchedSignup && !usersState.errorSignup) {
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    }
  }, [navigate, usersState.errorSignup, usersState.fetchedSignup]);

  return (
    <article className="signup">
      <section className="signup__container">
        <section className="signup__container__content">
          <h2>Inregistreaza-te</h2>
          <p>
            Ai deja cont pe 2x4.ro?
            <a href="#" onClick={() => navigate('/login')}>
              {' '}
              Autentifica-te
            </a>
            .
          </p>
          <SignupForm />
        </section>
        <ConnectSocial />
      </section>
    </article>
  );
};

export default Signup;
