/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Input from '../../shared/Input/Input';
import Button from '../../shared/Button/Button';
import LogMessage from '../../shared/LogMessage/LogMessage';
import { getSignup, resetUsers } from '../../actions/users';
import './SignupForm.scss';

const SignupForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [displayError, setDisplayError] = useState(null);

  const usersState = useSelector((state) => state.users, shallowEqual);

  useEffect(() => {
    if (usersState.fetchedSignup) {
      setDisplayError(null);

      if (usersState.fetchedSignup && usersState.errorSignup) {
        setDisplayError({
          type: 'error',
          message: usersState.errorSignup.error
        });
      }

      if (usersState.fetchedSignup && !usersState.errorSignup) {
        setDisplayError({
          type: 'success',
          message: 'Contul tau a fost creat cu success.'
        });

        formRef.current.reset();
      }
    }
  }, [usersState.errorSignup, usersState.fetchedSignup]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const lastName = e.target[0].value;
      const firstName = e.target[1].value;
      const phoneNumber = e.target[2].value;
      const logEmail = e.target[3].value;
      const logPassword = e.target[4].value;

      const payload = {
        firstName,
        lastName,
        phoneNumber,
        logEmail,
        logPassword
      };

      dispatch(getSignup(payload));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(resetUsers());
    };
  }, [dispatch]);

  return (
    <form className="signup-form" onSubmit={handleSubmit} ref={formRef}>
      <div className="signup-form__row">
        <div className="signup-form__row__cell">
          <p>Nume *</p>
          <Input type="text" placeholder="Nume" required />
        </div>
        <div className="signup-form__row__cell">
          <p>Prenume *</p>
          <Input type="text" placeholder="Prenume" required />
        </div>
      </div>
      <div className="signup-form__row">
        <div className="signup-form__row__cell">
          <p>Numar de telefon *</p>
          <Input type="text" placeholder="Telefon" required />
        </div>
        <div className="signup-form__row__cell">
          <p>E-mail *</p>
          <Input type="email" placeholder="E-mail" required />
        </div>
      </div>
      <div className="signup-form__row">
        <div className="signup-form__row__cell">
          <p>Parola *</p>
          <Input type="password" placeholder="Parola" required />
        </div>
        <div className="signup-form__row__cell">
          <p>Confirma parola *</p>
          <Input type="password" placeholder="Confirma parola" required />
        </div>
      </div>

      <div className="signup-form__options">
        <div>
          <input id="rememberCheck" type="checkbox" required />
          <label htmlFor="rememberCheck">Accept termeni si conditii</label>
        </div>
      </div>
      <Button
        text={'Autentifica'}
        color="yellow"
        width={185}
        fetching={usersState.isFetchingSignup}
      />
      {displayError && <LogMessage type={displayError.type} message={displayError.message} />}
    </form>
  );
};

export default SignupForm;
