const apiBaseUrl = () => {
  return process.env.REACT_APP_API_URI;
};

const paths = {
  login: () => `${apiBaseUrl()}/auth/login`,
  signup: () => `${apiBaseUrl()}/auth/register`,
  userProfile: () => `${apiBaseUrl()}/users/myinfo`,
  forgotPassword: () => `${apiBaseUrl()}/users/requestPasswordReset`,
  resetPassword: (token) => `${apiBaseUrl()}/users/resetPassword/${token}`,
  logout: () => `${apiBaseUrl()}/auth/logout`
};

export default paths;
