import React from 'react';
import './ConnectSocial.scss';

const ConnectSocial = () => {
  return (
    <section className="connect-social">
      <div className="connect-social__container">
        <h2>Connect With Social</h2>
        <button className="connect-social__container__fb">
          <span></span>Facebook
        </button>
        <button className="connect-social__container__g">
          <span></span>Google
        </button>
      </div>
    </section>
  );
};
export default ConnectSocial;
