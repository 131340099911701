import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserNavigation from './UserNavigation/UserNavigation';
import Dashboard from '../../components/Dashboard/Dashboard';
import UserProfile from '../../components/UserProfile';
import MyListing from '../../components/MyListing';
import Favorites from '../../components/Favorites';
import AddListing from '../../components/AddListing';
import './UserBoard.scss';

const UserBoard = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  useEffect(() => {
    if (!tab) {
      navigate('/board/dashboard');
    }
  }, [navigate, tab]);

  return (
    <section className="user-board">
      <UserNavigation selected={tab} />
      <section className="user-board__container">
        {tab === 'dashboard' && <Dashboard />}
        {tab === 'user-profile' && <UserProfile />}
        {tab === 'my-listing' && <MyListing />}
        {tab === 'favorites' && <Favorites />}
        {tab === 'add-listing' && <AddListing />}
      </section>
    </section>
  );
};

export default UserBoard;
