import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import loading from '../../assets/preloader.gif';
import './Button.scss';

const Button = ({ text, color, width, fetching = false, ...rest }) => {
  const classConfig = classNames({
    button: true,
    'color-yellow': color === 'yellow'
  });

  return (
    <button className={classConfig} style={{ width }} {...rest} disabled={fetching}>
      {fetching ? <img src={loading} alt="" width={45} /> : text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  fetching: PropTypes.bool
};

export default Button;
