import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const usersState = useSelector((state) => state.users, shallowEqual);

  if (usersState.fetched && usersState.isLoggedIn) {
    return children;
  } else if (usersState.fetched && !usersState.isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return <div>Loading...</div>;
};

export default ProtectedRoutes;
